import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

const sliderClass = '.js-slider-reviews';

const sliderServices = new Swiper(sliderClass, {
    modules: [Autoplay, Navigation],
    // loop: true,
    spaceBetween: 16,
    slidesPerView: 1.05,
    speed: 450,
    navigation: {
        nextEl: '.reviews__head__arrows--right',
        prevEl: '.reviews__head__arrows--left',
    },
    autoplay: {
        delay: 3500,
    },
    breakpoints: {
        768: {
            slidesPerView: 1.5,
            spaceBetween: 20,
        },
        991: {
            slidesPerView: 'auto',
            spaceBetween: 20,
        },
    },
});